import { Link, Button, Stack, Chip } from '@mui/material'
import { DataGrid, GridColDef, GridFooter, GridFooterContainer } from '@mui/x-data-grid'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { appSelectors } from '@src/data/store/AppStore'
import { useAppSelector } from '../../data/store'
import {
  creditTaskDashboardEntryEffects,
  creditTaskDashboardEntrySelectors,
} from '../../data/store/CreditTaskDashboardEntry'
import CustomToolBar from './components/toolBar'
import FilterComponent from './components/filters'
import {
  CreditTaskDashboardFilters,
  CreditApplicationReservationDto,
  CreditApplicationReservation,
  CreditTaskDashboardEntry,
  EFinancingProgram,
  ValueListItem,
} from '../../data/types'
import {
  getNumberArrayFromQueryString,
  getNumberFromQueryString,
  getStringArrayFromQueryString,
  getStringFromQueryString,
} from '../../services/url'
import { userSelectors } from '../../data/store/UserStore'
import CountStatuses from './components/CountStatuses'
import FullScreenContainer from '../../components/FullScreenContainer'

const DEFAULT_FILTER_VALUES = {
  limit: 50,
  offset: 0,
  financingProgramIds: [] as string[],
  entryType: [] as string[],
  territoryIds: [] as number[],
  assignation: 'blank',
  financingCompanyId: '' as string,
  correspondanceLanguageId: [] as number[],
  trusteeName: '',
  statesIso: [] as string[],
}

const CreditTaskDashboardPage = () => {
  const { t } = useTranslation()
  const selectedCompany = useAppSelector(userSelectors.selectedCompany) as string
  const merchantTags = useAppSelector<ValueListItem[]>(appSelectors.getMerchantTags)
  const dispatchEffect = useSideEffect()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { location } = window
  const updateSearch = new URLSearchParams(location.search)

  const filterValues = useMemo(() => {
    return {
      limit: getNumberFromQueryString(searchParams, 'limit', 50),
      offset: getNumberFromQueryString(searchParams, 'offset', 0),
      financingProgramIds: getStringArrayFromQueryString(searchParams, 'financingProgramIds'),
      entryType: getStringArrayFromQueryString(searchParams, 'entryType'),
      territoryIds: getNumberArrayFromQueryString(searchParams, 'territoryIds'),
      assignation: getStringFromQueryString(searchParams, 'assignation', 'blank'),
      correspondanceLanguageId: getNumberArrayFromQueryString(searchParams, 'correspondanceLanguageId'),
      trusteeName: getStringFromQueryString(searchParams, 'trusteeName'),
      statesIso: getStringArrayFromQueryString(searchParams, 'statesIso'),
      financingCompanyId: selectedCompany,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const taskList = useAppSelector(creditTaskDashboardEntrySelectors.getTaskList)
  const statusCount = useAppSelector(creditTaskDashboardEntrySelectors.getStatusCount)
  const isLoading = useAppSelector(creditTaskDashboardEntrySelectors.isLoadingTaskList)
  const user = useAppSelector(userSelectors.getUser)
  const trusteeNames = useAppSelector(creditTaskDashboardEntrySelectors.getTrusteeNames)
  const userCanEditCreditApplication = user?.rights.canManageDocuments || user?.rights.canManageTasks

  useEffect(() => {
    dispatchEffect(creditTaskDashboardEntryEffects.getTrusteeNames()).catch(reportErrorToConsole)
  }, [dispatchEffect])

  useEffect(() => {
    dispatchEffect(creditTaskDashboardEntryEffects.getTaskListByFilters(filterValues)).catch(reportErrorToConsole)
    dispatchEffect(creditTaskDashboardEntryEffects.getStatusCount(filterValues)).catch(reportErrorToConsole)
  }, [filterValues, dispatchEffect])

  const handleFilter = (newValue: CreditTaskDashboardFilters) => {
    updateSearch.set('limit', encodeURIComponent(newValue.limit))
    updateSearch.set('offset', encodeURIComponent(newValue.offset))
    updateSearch.set('financingProgramIds', encodeURIComponent(newValue.financingProgramIds.toString()))
    updateSearch.set('entryType', encodeURIComponent(newValue.entryType.toString()))
    updateSearch.set('correspondanceLanguageId', encodeURIComponent(newValue.correspondanceLanguageId.toString()))
    updateSearch.set('assignation', encodeURIComponent(newValue.assignation.toString()))
    updateSearch.set('trusteeName', encodeURIComponent(newValue.trusteeName.toString()))
    updateSearch.set('statesIso', encodeURIComponent(newValue.statesIso.toString()))
    updateSearch.set('financingCompanyId', encodeURIComponent(selectedCompany.toString()))
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  const handleReserve = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      const values = e.currentTarget.value.split(',')
      const entityLockToAdd: CreditApplicationReservationDto = {
        financingProgramId: values[1] as EFinancingProgram,
        creditApplicationId: values[0],
        durationInMinutes: 20,
      }
      dispatchEffect(creditTaskDashboardEntryEffects.reserve(entityLockToAdd))
        .then((data: CreditApplicationReservation) => {
          if (data.isAcquired) window.open(`/Applications/${values[1]}/${data.creditApplicationId}/view`, '_blank')
        })
        .catch(reportErrorToConsole)
    },
    [dispatchEffect],
  )

  const columns = useMemo<GridColDef<CreditTaskDashboardEntry>[]>(
    () => [
      {
        field: 'creditApplicationReferenceNumber',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.applicationNumber'),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <Link href={`/Applications/${params.row.financingProgramId}/${params.row.id}/view`}>
              {params.row.creditApplicationReferenceNumber}
            </Link>
          )
        },
      },
      {
        field: 'reservedByUserId',
        headerName: t('taskManager.reservedBy'),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          if (params.row.reservedByUserId === null) {
            return (
              <Button
                variant="outlined"
                value={[params.row.id, params.row.financingProgramId]}
                onClick={handleReserve}
                disabled={!userCanEditCreditApplication}
              >
                {t('taskManager.reserve')}
              </Button>
            )
          }
          if (user?.id === params.row.reservedByUserId) {
            return <div>{t('taskManager.yourself')}</div>
          }
          return <div>{params.row.reservedByUserName}</div>
        },
      },
      { field: 'merchantName', headerName: t('taskManager.merchantName'), flex: 1, sortable: false },
      { field: 'clientName', headerName: t('taskManager.clientName'), flex: 1, sortable: false },
      { field: 'stateIso', headerName: 'Province', flex: 1, sortable: false },
      {
        field: 'tags',
        headerName: t('taskManager.tags'),
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Stack gap={0.5} direction="row" flexWrap="wrap">
            {params.row.tags?.map((tag) => <Chip key={tag} label={merchantTags.find((mt) => mt.id === tag)?.text} />)}
          </Stack>
        ),
      },
      {
        field: 'followUpReason',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.followUpReason'),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return <div>{t(`taskManager.${params.row.actions[0]?.actionType}`)}</div>
        },
      },
    ],
    [t, user?.id, handleReserve, userCanEditCreditApplication, merchantTags],
  )
  const handleOnPageChange = (state: number) => {
    if (state === 0) {
      updateSearch.set('offset', encodeURIComponent(filterValues.offset - filterValues.limit))
    } else {
      updateSearch.set('offset', encodeURIComponent(filterValues.offset + filterValues.limit))
    }
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  const customToolBar = () => <CustomToolBar totalItems={taskList.length} />
  const customFooter = () => {
    return (
      <GridFooterContainer>
        <GridFooter sx={{ '& .MuiTablePagination-actions': { display: 'none' } }} />
        <div style={{ display: 'flex' }}>
          <Button onClick={() => handleOnPageChange(0)} disabled={filterValues.offset === 0}>
            {' '}
            {t('common.previous')}
          </Button>
          <Button onClick={() => handleOnPageChange(1)} disabled={taskList.length < filterValues.limit}>
            {' '}
            {t('common.next')}
          </Button>
        </div>
      </GridFooterContainer>
    )
  }

  return (
    <FullScreenContainer>
      <FilterComponent
        filterValues={filterValues}
        resetFilter={() => handleFilter(DEFAULT_FILTER_VALUES)}
        applyFilter={handleFilter}
        trusteeNames={trusteeNames}
      />
      <CountStatuses statusCount={statusCount} />
      <div style={{ height: '36rem' }}>
        {taskList.length === 0 ? <h3> {t('common.noResultFound')} </h3> : ''}
        <DataGrid
          rows={taskList}
          columns={columns}
          checkboxSelection
          disableColumnFilter
          disableColumnMenu
          pageSize={filterValues.limit}
          onPageSizeChange={(size: number) => handleFilter({ ...filterValues, limit: size })}
          rowCount={taskList.length}
          loading={isLoading}
          components={{
            Toolbar: customToolBar,
            Footer: customFooter,
          }}
        />
      </div>
    </FullScreenContainer>
  )
}

export default CreditTaskDashboardPage
